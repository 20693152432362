import { useEffect, useMemo } from 'react';
import Select, { type SingleValue } from 'react-select';

import { type ClientLLMProviderItem } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';

export function LLMProviderSelect(props: {
  providers: ClientLLMProviderItem[];
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}) {
  const styles = useMemo(() => buildReactSelectStyles(), []);
  const providerOptions = props.providers.map((p) => ({
    label: p.name,
    value: p.name,
  }));
  return (
    <Select<Option<string>>
      classNamePrefix='select-box-v2'
      styles={styles}
      value={providerOptions.find((o) => o.value === props.value)}
      onChange={(e) => {
        if (e?.value) props.onChange(e.value);
      }}
      options={providerOptions}
      isSearchable={false}
      isDisabled={props.isDisabled}
      isClearable={false}
    />
  );
}

export function LLMModelSelect(props: {
  provider: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  providers: ClientLLMProviderItem[];
}): JSX.Element {
  const { provider, providers, value } = props;
  const styles = useMemo(() => buildReactSelectStyles(), []);

  const options = useMemo(() => {
    return (
      providers
        .find((p) => p.name === provider)
        ?.models.map((m) => ({ label: m, value: m })) ?? []
    );
  }, [provider, providers]);

  const onChange = useLiveCallback(props.onChange);

  useEffect(() => {
    const models = providers.find((p) => p.name === provider)?.models;
    if (!models || models.includes(value)) return;
    onChange(models[0]);
  }, [onChange, provider, providers, value]);

  const handleSingleChange = (option: SingleValue<Option<string>>) => {
    if (option) {
      onChange(option.value);
    }
  };

  return (
    <Select<Option<string>, false>
      placeholder={`Select model`}
      styles={styles}
      options={options}
      value={options.find((o) => o.value === props.value)}
      classNamePrefix='select-box-v2'
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No model matched';
      }}
      onChange={handleSingleChange}
      isDisabled={props.isDisabled}
    />
  );
}
